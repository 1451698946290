import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { LoaderFull } from "./components/Utils";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

function App() {
  const Login = lazy(() => import("./containers/LoginContainer"));

  const NotificationsList = lazy(() =>
    import("./containers/NotificationsList")
  );
  const NotificationsCategoriesList = lazy(() =>
    import("./containers/NotificationsCategoriesList")
  );
  const ClassCreate = lazy(() => import("./containers/ClassCreate"));
  const ClassesList = lazy(() => import("./containers/ClassesList"));
  const NotificationCreate = lazy(() =>
    import("./containers/NotificationCreate")
  );
  const NotificationCategoryCreate = lazy(() =>
    import("./containers/NotificationCategoryCreate")
  );
  const NotificationView = lazy(() => import("./containers/NotificationView"));
  const Page404 = lazy(() => import("./containers/Page404"));

  return (
    <Suspense fallback={<LoaderFull />}>
      <ReactNotifications />
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<PrivateRoute />}>
          <Route path="/" element={<NotificationsList />} />
        </Route>
        <Route exact path="/classes" element={<PrivateRoute />}>
          <Route path="/classes" element={<ClassesList />} />
        </Route>
        <Route exact path="/class/create" element={<PrivateRoute />}>
          <Route path="/class/create" element={<ClassCreate />} />
        </Route>
        <Route exact path="/notification-categories" element={<PrivateRoute />}>
          <Route
            path="/notification-categories"
            element={<NotificationsCategoriesList />}
          />
        </Route>
        <Route
          exact
          path="/notification-category/create"
          element={<PrivateRoute />}
        >
          <Route
            path="/notification-category/create"
            element={<NotificationCategoryCreate />}
          />
        </Route>
        <Route exact path="/notification/create" element={<PrivateRoute />}>
          <Route path="/notification/create" element={<NotificationCreate />} />
        </Route>
        <Route exact path="/notification/:id" element={<PrivateRoute />}>
          <Route path="/notification/:id" element={<NotificationView />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
}

export default App;
